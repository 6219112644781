import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../layouts'
import Feature from '../components/Feature'
import Sidebar from '../components/Sidebar'

const meta = {
  title: 'PAT Testing Services',
  url: 'https://www.maxpowerlimited.com/pat-testing/',
}

const PatTestingPage = props => (
  <Layout {...props}>
    <Helmet
      title={meta.title}
      meta={[
        { itemprop: 'name', content: meta.title },
        { property: 'og:title', content: meta.title },
        { property: 'og:url', content: meta.url },
        { name: 'twitter:title', content: meta.title },
        { name: 'twitter:url', content: meta.url },
      ]}
      link={[{ rel: 'canonical', href: meta.url }]}
    />

    <Feature title={meta.title} />

    <div className="page">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-lg-8">
            <h2 title="Portable Appliance Testing (PAT)">
              Portable Appliance Testing (PAT)
            </h2>

            <p>
              Portable appliance testing, commonly known as PAT testing, is a
              way of checking your electrical equipment to see if it is safe and
              is vital to protect your business and its employees.
            </p>

            <div className="panel">
              <p>
                <strong>In-Service testing will involve the following:</strong>
              </p>

              <ul>
                <li>Preliminary inspection</li>
                <li>Earth continuity tests (for Class 1 equipment)</li>
                <li>
                  Insulation testing (Which may sometimes be substituted by
                  earth leakage measurement)
                </li>
                <li>Functional checks</li>
              </ul>
            </div>

            <p>
              Electrical testing should be performed by a person who is
              competent in the safe use of the test equipment and who knows how
              to interpret the test results obtained. This person must be
              capable of inspecting the equipment and, where necessary,
              dismantling it to check the cable connections.
            </p>

            <p>
              If equipment is permanently connected to the fixed installation,
              e.g. by a flex outlet or other accessory, the accessory will need
              to be detached from its box or enclosure so that the connections
              can be inspected. Such work should only be carried out by a
              competent person.
            </p>

            <p>
              We aim to bring your company the most competitive possible rates
              with none of those hidden costs. This is why Maxpower Limited
              include and DO NOT charge for any minor repairs or fuse changes
              that our engineers can carry out as they work through your
              workplace.{' '}
            </p>

            <p>
              Any minor repairs that we come across, where possible we will
              rectify the problem there and then. This can include something as
              small and simple as replacing a wrongly rated fuse, a cracked or
              damaged plug or even the shortening and re-wiring of cables where
              feasible and/or permission given by the onsite contact.
            </p>
          </div>
          <div className="d-none d-lg-block col-lg-3 offset-lg-1">
            <Sidebar title={meta.title} />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default PatTestingPage
